<template>
    <div>

    </div>
</template>
<script>

    export default {
        data() {
            return {
                login: {
                    email: '',
                    password: '',
                    rememberme: false
                }
            }
        },
        mounted() {
            if (this.$route.params.status === 'success') {
                this.$store.commit('emailVerified');
                this.$swal.fire('Email verified', 'Your email is verified now', 'success');
                this.redirect();
            } else if (this.$route.params.status === 'failed') {
                this.$swal.fire('Email verification failed', 'Your verification email is expired or is already verified', 'error');
                this.redirect();
            } else if (this.$route.params.status === 'already-verified') {
                this.$store.commit('emailVerified');
                this.$swal.fire('Email already verified', 'Your link is expired, this Account-email is already verified', 'warning');
                this.redirect();
            }
        },
        methods: {
            redirect() {
                if (this.$store.state.user) {
                    this.$router.push({name: 'Dashboard'});
                } else {
                    this.$router.push({name: 'Login'});
                }
            }
        }
    }
</script>
